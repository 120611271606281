import React from "react";
import { Box, Grid } from "@material-ui/core";
import { AdBannersType } from "../../../types/adBanners";
import { Position } from "API";
import { makeStyles } from "@material-ui/core/styles";

type Props = {
  position: Position;
  adBanners: AdBannersType;
  isFullWidth?: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1260px",
    },
  },
  item: {
    [theme.breakpoints.up("xs")]: {
      padding: "0 ",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 16px",
    },
  },
}));

function Page(props: Props) {
  const classes = useStyles();

  const { position, adBanners, isFullWidth = false } = props;
  const _adBanners = adBanners[position];

  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box className={classes.container}>
        {_adBanners && _adBanners.length > 0 && (
          <Grid
            container
            style={{
              marginBottom: "50px",
            }}
          >
            {_adBanners.map((_adBanner: any, key: any) => (
              <Grid
                item
                xs={12}
                sm={isFullWidth ? 12 : 6}
                key={key}
                className={classes.item}
                style={{
                  marginBottom: "16px",
                }}
              >
                <a href={_adBanner.ad.url} rel="noreferrer" target="_blank">
                  {_adBanner.media && (
                    <img
                      src={_adBanner.media.url}
                      alt={_adBanner.media.name}
                      style={{
                        width: "100%",
                      }}
                    />
                  )}
                </a>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
}

export default Page;
