import React from "react";

import movieBackgroundImageUrl from "assets/img/home/bg-movie.jpg";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backgroundImageContainer: {
    backgroundImage: `url("${movieBackgroundImageUrl}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "604px",
  },
  button: {
    border: "1px solid rgb(255,255,255, 0.5)",
    minWidth: "321px",
  },
  boldText: {
    color: "white",
    fontWeight: "bold",
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    padding: "0px 40px",
  },
  leftContainer: {
    marginTop: "44px",
    marginBottom: "44px",
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "black",
  },
  channelTitle: {
    paddingTop: "32px",
  },
  movieLink: {
    paddingBottom: "32px",
  },
}));

type Props = {
  youtubes: {
    priority: number;
    src: string;
  }[];
};

export default function KaziMovie(props: Props) {
  const { youtubes } = props;

  const classes = useStyles();

  return (
    <div className={classes.backgroundImageContainer}>
      <Grid container>
        <Grid
          container
          spacing={7}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingLeft: "50px",
            marginTop: "15px",
          }}
        >
          {youtubes?.length &&
            youtubes.map((youtube) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={youtube.src}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "14px",
                  }}
                >
                  <iframe
                    // @ts-ignore
                    loading="lazy"
                    title="Kazi Movieの動画"
                    src={youtube.src}
                    frameBorder="0"
                  ></iframe>
                </Grid>
              );
            })}
        </Grid>

        <Grid container alignItems="center" className={classes.leftContainer}>
          <Grid item className={classes.channelTitle} xs={12}>
            <Typography variant="h6" className={classes.boldText}>
              舵社Youtubeチャンネル
              <br />
              「Kazi Movie」
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Button
              size="large"
              variant="outlined"
              className={classes.button}
              href="https://www.youtube.com/c/kazimovie"
              target="_blank"
            >
              <Typography variant="button" className={classes.buttonText}>
                動画チャンネルを見る
              </Typography>
            </Button>
          </Grid>

          <Grid item xs={12} className={classes.movieLink}>
            <Button
              size="large"
              variant="outlined"
              className={classes.button}
              href="https://www.kazi-online.com/pages/kazi_movie_boat_index"
              target="_blank"
            >
              <Typography variant="button" className={classes.buttonText}>
                ヨット＆ボート紹介動画 INDEX
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
