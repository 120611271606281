import React, { useEffect } from "react";

import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import { Box, Button, Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles({
  hideIcon: {
    "& .MuiSvgIcon-root": {
      display: "none",
    },
  },
  displayIcon: {
    "& .MuiSvgIcon-root": {
      display: "block",
    },
  },
});

export default function Search() {
  const classes = useStyles();
  const location = useLocation();

  const [keyword, setKeyword] = React.useState("");
  const [isComposing, setIsComposing] = React.useState(false);

  const handleRequestSearch = () => {
    const encodedKeyword = keyword ? encodeURI(keyword) : "";

    if (encodedKeyword && encodedKeyword.trim() !== "") {
      navigate(`/search-result?keyword=${encodedKeyword}`);
    }
  };

  // Enter キー押下時の処理
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // isComposing が false のとき (変換終了後) の Enter だけ検索実行
    if (!isComposing && e.key === "Enter") {
      handleRequestSearch();
    }
  };

  useEffect(() => {
    const url = new URL(location.href ?? "");
    const _keyword = new URLSearchParams(url.search ?? "").get("keyword") ?? "";
    setKeyword(_keyword);
  }, [location]);

  return (
    <>
      <div style={{ backgroundColor: "rgb(247, 249, 249)" }}>
        <Box display="flex" flexDirection="row">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width={{
              xs: "100%",
              md: "300px",
            }}
            style={{
              padding: "0 16px",
              backgroundColor: "#FFFFFF",
              border: "solid #99CCFF 1px",
              borderRight: "none",
              borderRadius: "3px 0 0 3px",
            }}
          >
            <Input
              type="search"
              placeholder="キーワードでさがす"
              onKeyDown={handleKeyDown}
              onCompositionStart={() => setIsComposing(true)}
              onCompositionEnd={() => setIsComposing(false)}
              value={keyword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setKeyword(e.target.value)
              }
              className={`${
                keyword.length > 0 ? classes.displayIcon : classes.hideIcon
              }`}
              disableUnderline
              style={{
                height: "35px",
                width: "100%",
                borderRadius: "3px 0 0 3px",
                boxShadow: "none",
                border: "none",
              }}
            />
          </Box>

          <Button
            style={{
              color: "#005580",
              backgroundColor: "#c0d5ec",
              border: "solid #c0d5ec 1px",
              borderRadius: "0 3px 3px 0",
              padding: "0",
              minWidth: "36px",
            }}
            onClick={handleRequestSearch}
          >
            <SearchIcon
              style={{
                color: "#1085C4",
                width: "36px",
              }}
            />
          </Button>
        </Box>
      </div>
    </>
  );
}
